import axios from 'axios';
import * as Api from './../api-urls/Api';
import { GENERIC_ERROR, OTP_VIA_SMS, REQUESTOR_GENERATE, AUTH_METHOD_WAVE } from '../constants';

const _getServiceInfo = (serviceName) => {
  return axios.get(Api.GET_SERVICE_INFO.replace(":service", serviceName))
}

const _getAuthData = (serviceName) => {
  return axios.get(Api.GET_SERVICE_AUTH_DATA.replace(":service", serviceName))
}

const _getEnrolData = (serviceName, token) => {
  return axios.get(Api.GET_ENROL_DATA.replace(":service", serviceName).replace(":token", token))
}


const _errorObject = (message) => {
  return {
    error                : true,
    modalOpen            : true,
    modalHeader          : "Error",
    modalError           : message,
    modalIcon            : "exclamation triangle",
    modalSize            : "small",
    modalShowActionButton: false,
    modalFixHeight       : "",
    modalScrolling       : false,
    modalCustomWidth     : "",
  }
}

const _errorMessage = (message) => {
  return {
    error: true, 
    modalError: message
  }
}

class HTTPSComm {
  constructor() {
    let seed = Math.floor(Math.random() * 1000000);
    axios.defaults.headers.seed = seed;
    axios.defaults.withCredentials = true;
  }

  _debug = (...message) => {
    if (process.env.REACT_APP_DEBUG) {
      console.log(...message);
    }
  }

  getCISInfo = async() => {
    try {
      let info = await axios.get(Api.INFO);
      this._debug("CIS INFO:", info.data)
      return info.data;
    } catch (e) {
      return _errorMessage(e.message)
    }
  }

  authenticated = async(serviceName) => {
    try {
      let res = await axios.get(Api.GET_AUTHENTICATED.replace(":service", serviceName))
      this._debug("Authenticated result:", res.data);
      // if (!res.data.error) {
      //   return { error: false }
      // } else {
      //   return { error: true, username: "" }
      // } 
      return res.data
    } catch (e) {
      return { error: true, error_message: e.message }
    }
  }

  getAuthentication = async(serviceName) => {
    try {
      let info = await _getServiceInfo(serviceName);
      let auth = await _getAuthData(serviceName)

      this._debug("Info:", info.data);
      this._debug("Service:", auth.data);

      if ((auth.data.error && auth.data.error_message !== "already authenticated") || info.data.error) {
        if (info.data.error_message && info.data.error_message.toLowerCase() === "service not found") {
          return { notConfigured: true }
        } 
        return _errorObject(GENERIC_ERROR);
      } else {
        let result = {
          serviceName,
          serviceFriendlyName   : info.data.friendlyName,
          serviceLogo           : info.data.logoURL ? (info.data.logoURL + "?" + Date.now())              : info.data.logoURL,
          serviceType           : info.data.type,
          selfEnrolment         : info.data.selfEnrolment,
          selfEnrolButtonDisplay: info.data.selfEnrolButtonDisplay,
          colorBand             : info.data.colorBand,
          brandedImage          : info.data.brandedImageURL ? info.data.brandedImageURL + "?" + Date.now(): info.data.brandedImageURL,
          brandedStyle          : info.data.brandedStyle,
          defaultAuthMethod     : info.data.defaultAuthMethod || AUTH_METHOD_WAVE,
          alternateAuth         : info.data.alternateAuth || "",
          adminMessage          : info.data.adminMessage,
          userAgent             : info.data.userAgent || "",
          qrURLScheme           : info.data.qrURLScheme,
          smsConfigured         : info.data.smsConfigured
        };
        let getStatus = false;
        if (auth.data.error_message === "already authenticated") {
          Object.assign(result, {alreadyAuthenticated: true, username: auth.data.username, displayName: auth.data.displayName});
        } else if (auth.data.interaction !== "push") {
          Object.assign(result, {...auth.data, image: auth.data.authQrCodeURL});
          getStatus = auth.data.interaction === AUTH_METHOD_WAVE && info.data.defaultAuthMethod === AUTH_METHOD_WAVE;
        } else {
          Object.assign(result, {...auth.data, alreadyAuthenticated: true});
        }
        
        Object.assign(result, {serviceFriendlyName: info.data.friendlyName, authServiceType: info.data.type})
        return { error: false, ...result, getStatus }
      }
    } catch(e) {
      return _errorObject(e.message);
    }
  }

  authenticate = async(serviceName) => {
    try {
      let res = await axios.get(Api.GET_SERVICE_AUTH_DATA.replace(':service', serviceName))
      let result = {};
      this._debug("Authenticate: ", res.data)
      if (!res.data.error || res.data.error_message === "already authenticated") {
        Object.assign(result, {...res.data, image: res.data.authQrCodeURL});
        if (res.data.username) {
          Object.assign(result, {username: res.data.username, displayName: res.data.displayName})
        }
        return { error: false, ...result }
      } else {
        return _errorObject("Fail to authenticate due to " + res.data.error_message);
      }
    } catch (e) {
      return _errorObject(e.message);
    }
  }

  pushAuth = async(serviceName, deviceId) => {
    try {
      let res = await axios.post(Api.SERVICE_PUSH_AUTH.replace(":service", serviceName), {deviceId})
      this._debug("Push auth: ", res.data)
      if (!res.data.error) {
        let result = {...res.data}
        if (res.data.username) {
          Object.assign(result, {username: res.data.username, displayName: res.data.displayName})
        } else {
          Object.assign(result, {getStatus: true})
        }
        return result;
      } else {
        return _errorObject(res.data.error_message);
      }
    } catch(e) {
      return _errorObject(e.message);
    }
  }

  pushDeviceAuth = async(serviceName, token, deviceId) => {
    try {
      let res = await axios.post(Api.DEVICE_AUTH.replace(":service", serviceName).replace(":token", token), {deviceId})
      this._debug("Push device auth: ", res.data)
      if (!res.data.error) {
        let result = {...res.data}
        if (res.data.username) {
          Object.assign(result, {username: res.data.username, displayName: res.data.displayName})
        } else {
          Object.assign(result, {getStatus: true})
        }
        return result;
      } else {
        return _errorObject(res.data.error_message);
      }
    } catch(e) {
      return _errorObject(e.message);
    }
  }

  pushDeviceAuthStatus = async(serviceName, token) => {
    try {
      let c = new Date();
      this._debug("Attempting to call device auth status ", c.toTimeString())
      let res = await axios.get(Api.DEVICE_AUTH_STATUS.replace(':service', serviceName).replace(':token', token))
      this._debug("Device Auth Status", res.data);
      return res.data;
    } catch (e) {
      return {
        error_message: "https timeout",
        error: true,
      }
    }
  }

  authStatus = async(serviceName, token) => {
    try {
      let c = new Date();
      this._debug("Attempting to call status ", c.toTimeString())
      let res = await axios.get(Api.GET_SERVICE_AUTH_STATUS.replace(':service', serviceName).replace(':token', token))
      this._debug("Auth Status", res.data);
      return res.data;
    } catch (e) {
      return {
        error_message: "https timeout",
        error: true,
      }
    }
  }

  logout = async(serviceName) => {
    try {
      let api = "";
      if (serviceName) api = Api.LOGOUT.replace(":name", serviceName);
      else api = Api.LOGOUT_ALL;
  
      let res = await axios.get(api)
      if (!res.data.error) {
        return { error: false }
      } else {
        return _errorObject(res.data.error_message)
      }
    } catch (e) {
      return _errorObject(e.message);
    }
  }
  
  submitLegacyAuth = async(serviceName, username, password) => {
    try {
      let res = await axios.post(Api.SET_LEGACY_AUTH.replace(":service", serviceName), { username, password })
      this._debug("Legacy Auth result:", res.data)
      if (!res.data.error) {
        return res.data;
      } else {
        return _errorObject(res.data.error_message)
      }
    } catch (e) {
      return _errorObject(e.message);
    }
  }
  
  getOTPViaSMS = async(serviceName, token) => {
    try {
      let url = Api.SET_OTP_AUTH_SMS.replace(":service", serviceName);
      let res = await axios.post(url, { token });
      this._debug("OTP VIA SMS:", res.data);
      if (!res.data.error) {
        return res.data;
      } else {
        return _errorObject(res.data.error_message)
      }
    } catch (e) {
      return _errorObject(e.message);
    }
  }
  
  getOTPViaAPP = async(serviceName, token) => {
    try {
      let url = Api.SET_OTP_AUTH_APP.replace(":service", serviceName);
      let res = await axios.post(url, { token });
      if (!res.data.error) {
        return res.data;
      } else {
        return _errorObject(res.data.error_message)
      }
    } catch (e) {
      return _errorObject(e.message);
    }
  }

  getOTPSeed = async(serviceName, token) => {
    try {
      let url = Api.SET_OTP_AUTHENTICATOR.replace(":service", serviceName);
      let res = await axios.post(url, { token });
      if (!res.data.error) {
        return res.data;
      } else {
        return _errorObject(res.data.error_message)
      }
    } catch (e) {
      return _errorObject(e.message);
    }
  }

  setAuthenticatorOTP = async(serviceName, token, otp, trustBrowser) => {
    try {
      let url = Api.SET_OTP_AUTHENTICATOR.replace(":service", serviceName);
      let res = await axios.post(url, { token, otp, trustBrowser });
      if (!res.data.error) {
        return res.data;
      } else {
        return _errorObject(res.data.error_message)
      }
    } catch (e) {
      return _errorObject(e.message);
    }
  }
  
  submitOTPCode = async(serviceName, mode, otp, token) => {
    try {
      let url;
      this._debug("Submitting OTP code", serviceName, mode, token, otp);
      if (mode === OTP_VIA_SMS) url = Api.SET_OTP_AUTH_SMS.replace(":service", serviceName);
      else url = Api.SET_OTP_AUTH_APP.replace(":service", serviceName);
  
      let res = await axios.post(url, { token, otp });
      if (!res.data.error) {
        return res.data;
      } else {
        return _errorObject(res.data.error_message)
      }
    } catch (e) {
      return _errorObject(e.message);
    }
  }
  
  submitPOKCode = async(serviceName, username, pok) => {
    try {
      let res = await axios.post(Api.SET_POK_AUTH.replace(":service", serviceName), { username, pok });
      this._debug("POK Response:", res.data)
      if (!res.data.error) {
        return res.data;
      } else {
        return _errorObject(res.data.error_message);
      }
    } catch (e) {
      return _errorObject(e.message);
    }
  }

  submitTOTPCode = async(serviceName, username, totp) => {
    try {
      let res = await axios.post(Api.SET_TOTP_AUTH.replace(":service", serviceName), { username, totp });
      this._debug("POK Response:", res.data)
      if (!res.data.error) {
        return res.data;
      } else {
        return _errorObject(res.data.error_message);
      }
    } catch (e) {
      return _errorObject(e.message);
    }
  }
  
  submitVouchingUser = async(serviceName, username, token) => {
    try {
      let res = await axios.post(Api.SET_VOUCHING_AUTH.replace(":service", serviceName), { token, username });
      this._debug("Vouching Response:", res.data)
      if (!res.data.error) {
        return res.data;
      } else {
        return _errorObject(res.data.error_message);
      }
    } catch (e) {
      return _errorObject(e.message);
    }
  }
  
  getVouchingStatus = async(serviceName, token) => {
    try {
      let res = await axios.get(Api.GET_VOUCHING_STATUS.replace(":service", serviceName).replace(":token", token));
      this._debug("Vouching Status Response:", res.data)
      if (!res.data.error) {
        return res.data;
      } else {
        return _errorObject(res.data.error_message);
      }
    } catch (e) {
      return _errorObject(e.message);
    }
  }

  getAuthTeleport = async(serviceName) => {
    try {
      this._debug("Calling get Auth teleport", serviceName)
      let res = await axios.get(Api.AUTH_TELEPORT.replace(":service", serviceName));
      this._debug("Get Auth Teleport", res.data);
      if (!res.data.error) {
        return {error: false, teleportKey: res.data.key};
      } else {
        return _errorMessage(res.data.error_message);
      }
    } catch(e) {
      return _errorMessage(e.message);
    }
  }

  setAuthTeleport = async(serviceName, key, direction = REQUESTOR_GENERATE) => {
    try {
      this._debug("Calling Teleport with", serviceName, key, direction);
      let res = await axios.post(Api.AUTH_TELEPORT.replace(":service", serviceName), {key, direction});
      this._debug("Set Auth Teleport", res.data);
      if (!res.data.error) {
        let {data} = res.data;
        return {
          error            : false,
          ip_origin        : data.ip,
          teleportCountry  : data.country,
          teleportCity     : data.city,
          teleportRegion   : data.region,
          teleportLat      : data.latitude,
          teleportLong     : data.longitude,
          teleportUserAgent: data.useragent
        }
      } else {
        return _errorObject(res.data.error_message);
      }
    } catch(e) {
      return _errorObject(e.message);
    }
  }

  authTeleportStatus = async(serviceName) => {
    try {
      let res = await axios.get(Api.AUTH_TELEPORT_STATUS.replace(":service", serviceName));
      this._debug("Auth Teleport Status", res.data);
      let { status, timeRemaining, data } = res.data;
      if (!res.data.error) {
        if (data) {
          return {
            teleportStatus: status,
            teleportTimeRemaining: timeRemaining,
            ip_origin        : data.ip,
            teleportCountry  : data.country,
            teleportCity     : data.city,
            teleportRegion   : data.region,
            teleportLat      : data.latitude,
            teleportLong     : data.longitude,
            teleportUserAgent: data.useragent
          }
        } else {
          return {
            teleportStatus: status,
            teleportTimeRemaining: timeRemaining
          }
        }
      } else {
        return _errorObject(res.data.error_message);
      }
    } catch(e) {
      return _errorObject(e.message);
    }
  }

  deleteTeleport = async(serviceName) => {
    try {
      let res = await axios.delete(Api.DEL_TELEPORT_AUTH.replace(":service", serviceName));
      this._debug("Delete Auth Teleport", res.data);
      if (!res.data.error) {
        return res.data;
      } else {
        return _errorObject(res.data.error_message);
      }
    } catch(e) {
      return _errorObject(e.message);
    }
  }

  /******************************************** ENROLMENT APIs ********************************************/
  getEnrolment = async(serviceName, token) => {
    try {
      let info = await _getServiceInfo(serviceName);
      let enrol = await _getEnrolData(serviceName, token);

      this._debug("Info:", info.data);
      this._debug("Service:", enrol.data);

      let result = { enrol: {}, info:{}};

      result.info = info.data;
      result.enrol = enrol.data;
      
      return result;
    } catch (e) {
      return _errorObject(e.message)
    }
  }

  getEnrolmentStatus = async(serviceName, token) => {
    try {
      let res = await axios.get(Api.GET_ENROL_STATUS.replace(":service", serviceName).replace(":token", token));
      this._debug("Enrolment status", res.data)
      return res.data;
    } catch(e) {
      return _errorObject(e.message);
    }
  }

  enrolConfirm = async(serviceName, token) => {
    try {
      let res = await axios.post(Api.SET_ENROL_CONFIRM.replace(":service", serviceName).replace(":token", token), {confirm: "confirm"});
      return res.data;
    } catch (e) {
      return _errorObject(e.message);
    }
  }

  enrolDeny = async(serviceName, token) => {
    try {
      let res = await axios.post(Api.SET_ENROL_CONFIRM.replace(":service", serviceName).replace(":token", token), {confirm: "rejected"});
      return res.data;
    } catch (e) {
      return _errorObject(e.message);
    }
  }

  submitCP = async(serviceName, token, username, password) => {
    try {
      this._debug("Trying to submit cp:", username, password.length > 0)
      let res = await axios.post(Api.SET_CP_AUTH.replace(":service", serviceName).replace(":token", token), {username ,password});
      this._debug("CP Return:", res.data)
      if (!res.data.error) {
        return res.data;
      } else {
        return _errorObject(res.data.error_message);
      }
    } catch (e) {
      return _errorObject(e.message);
    }
  }

}





const httpsComm = new HTTPSComm();
Object.freeze(httpsComm);

export default httpsComm;