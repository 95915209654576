export const GET_AUTHENTICATED       = '/:service/authenticated';
export const GET_SERVICE_INFO        = '/:service/info';
export const GET_SERVICE_AUTH_DATA   = '/:service/auth';
export const GET_SERVICE_AUTH_STATUS = '/:service/auth/status/:token';
export const GET_SERVICE_AUTH        = '/:service/auth';
export const SERVICE_PUSH_AUTH       = '/:service/auth';
export const SET_LEGACY_AUTH         = '/:service/legacy/auth';
export const SET_OTP_AUTH_APP        = '/:service/legacy/otp/app';
export const SET_OTP_AUTH_SMS        = '/:service/legacy/otp/sms';
export const SET_OTP_AUTHENTICATOR   = "/:service/legacy/otp/authenticator";
export const SET_VOUCHING_AUTH       = '/:service/legacy/vouch';
export const GET_VOUCHING_STATUS     = '/:service/legacy/vouch/status/:token';
export const SET_POK_AUTH            = '/:service/pok';
export const SET_TOTP_AUTH           = '/:service/totp';
export const SERVICE_ENROL_PAGE      = '/:service/selfEnrol';
export const AUTH_TELEPORT           = '/:service/teleport';
export const AUTH_TELEPORT_STATUS    = '/:service/teleport/status';
export const DEL_TELEPORT_AUTH       = '/:service/teleport';

/******************************** DEAUTH API ********************************/
export const LOGOUT                  = '/:name/deauth';
export const LOGOUT_ALL              = '/deauth';
export const INFO = '/info';

export const GET_ENROL_DATA          = '/:service/enrol/data/:token';
export const GET_ENROL_STATUS        = '/:service/enrol/status/:token';
export const SET_ENROL_CONFIRM       = '/:service/enrol/confirm/:token';
export const SET_CP_AUTH             = '/:service/enrol/legacyAuth/:token';

export const DEVICE_AUTH        = "/:service/enrol/deviceAuth/:token";
export const DEVICE_AUTH_STATUS = "/:service/enrol/deviceAuth/status/:token";