export const ADMIN_SERVICE = "admin";

export const OTP_VIA_APP = "APP";
export const OTP_VIA_SMS = "SMS";

export const REQUESTOR_GENERATE = "out";
export const APPROVER_GENERATE  = "in";

export const AUTH_METHOD_WAVE     = "wave";
export const AUTH_METHOD_LEGACY   = "legacy";
export const AUTH_METHOD_OTP      = "otp";
export const AUTH_METHOD_POK      = "pok";
export const AUTH_METHOD_VOUCHING = "vouching";
export const AUTH_METHOD_TELEPORT = "teleport";

export const INTERACTION_PUSH = "push";
export const INTERACTION_LEGACY = "legacy";
export const INTERACTION_WAVE = "wave";

export const GENERIC_ERROR     = "Sorry, we are unable to process your request at the moment. Please try again later";

export const AUTH_PAGE = "auth";