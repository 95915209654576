import React, { createRef, Suspense, lazy } from 'react';
import { connect } from 'react-redux';


// Redux actions
import { UpdateReduxState } from './actions/common';

// Third party component
import { Modal, Message, Button, Header, Dimmer, Loader } from 'semantic-ui-react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// ThinOps Cypher Dashboard component
import './App.css';
import httpsComm from './comm/https';
const mapStateToProps = (state = {}) => {
  return { ...state };
};

const CISMenu        = lazy(() => import('./CISMenu'));
const Authentication = lazy(() => import('./Authentication'));
const CISFooter      = lazy(() => import('./CISFooter'));
const EnrolUser      = lazy(() => import('./EnrolUser'));
const CISModal       = lazy(() => import('./CISModal'));
class App extends React.Component {
  contextRef = createRef()

  constructor(props) {
    super(props);
    document.title = 'Cypher Integration Server';
    if ('ontouchstart' in document.documentElement) {
      document.body.style.cursor = 'pointer';
    }
    this.state = { isCrashed: false, showWarning: true, payloadData: "" }
    this.cipheriseLogoRef = createRef();
  }

  componentDidMount() {
    if (navigator.cookieEnabled) {
      this.getCISInfo();
    }
  }

  componentDidCatch(error, info) {
    this.setState({isCrashed: true})
  }

  focusLogo = () => {
    if (this.cipheriseLogoRef) {
      //Temporarily work around to move focus back to logo after each screen render.
      document.getElementById("cisLogoID").parentElement.focus();
    }
  }

  getCISInfo = async() => {
    let apiData = await httpsComm.getCISInfo();
    if (!apiData.error) {
      let enabledFeature = this.props.enabledFeature;
      if (apiData.branding === "aucloud") {
        enabledFeature.aucloud = true;
      }
      let { userAgent, serverVersion } = apiData;
      if (serverVersion && serverVersion === "N/A") serverVersion = "4.0.1";
      this.props.dispatch(UpdateReduxState({enabledFeature, userAgent, serverVersion, serverInfo: JSON.stringify(apiData)}))
    }
  }

  closeErrorModal =()=> {
    window.location.reload();
  }

  render() {
    if (!navigator.cookieEnabled) {
      return (
        <div className="App">
          <div className="App-content" ref={this.contextRef}>
            <Modal open={true}
              closeOnDimmerClick={false}
              closeOnEscape={false}
              size="small"
            >
              <Header  content="Error" />  
              <Modal.Content>
                <Message error icon="exclamation triangle" header="Sorry, we are unable to process your request at the moment." 
                  content="Cookies are required for continue accesing the site. Please enabled it in your browser and press 'Try again'"
                />
              </Modal.Content>
              <Modal.Actions>
                <Button color="grey" onClick={this.closeErrorModal} content="Try again" />
              </Modal.Actions>
            </Modal>
          </div>
        </div>
      )
    }

    return (
      <div className="App">
        { this.state.isCrashed ?
          <div className="App-content" ref={this.contextRef}>
            <Modal open={this.state.isCrashed}
              closeOnDimmerClick={false}
              closeOnEscape={false}
              size="small"
            >
              <Header  content="Error" />  
              <Modal.Content>
                <Message error icon="exclamation triangle" header="Sorry, we are unable to process your request at the moment. Please try again later" 
                  content="If this problem persists, please contact your system administrator."
                />
              </Modal.Content>
              <Modal.Actions>
                <Button color="grey" onClick={this.closeErrorModal} content="Try again" />
              </Modal.Actions>
            </Modal>
          </div>
          :
          <div className={"App-content"} ref={this.contextRef}>
            <Suspense 
              fallback={
                <Dimmer active>
                  <Loader indeterminate inverted content="Fetching service..." />
                </Dimmer>
              }
            >
              <CISMenu inputRef={this.cipheriseLogoRef} />
              <BrowserRouter>
                <Routes>
                  <Route path="/:id/selfEnrol" element={<EnrolUser isSelfEnrol={true} />} />
                  <Route path="/:id/enrol/*" element={<EnrolUser isSelfEnrol={false} />} />
                  <Route path="/*" element={<Authentication _focusFunction={this.focusLogo} />} />
                </Routes>
              </BrowserRouter>
              <CISFooter />
              <CISModal />
            </Suspense>
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(App);
